const CustomerList = () => import("@/views/customer/customer-list.view.vue");
const CustomerManage = () => import("@/views/customer/customer-manage.view.vue");
const CustomerAdd = () => import("@/views/customer/customer-add.view.vue");
export const customerRoutes = [
    {
        path: "/customer/add",
        name: "customer add",
        component: CustomerAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "users",
        },
    },
    {
        path: "/customer/list",
        name: "active customers",
        component: CustomerList,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: "users",
        },
    },
    {
        path: "/customer/:customer_id/:tab?/:sub_tab?/:value?",
        name: "customer manage",
        component: CustomerManage,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "users",
        },
    },
];
