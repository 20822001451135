const ProductAdd = () => import("@/views/product/product-add.view.vue");
export const productRoutes = [
    {
        path: "/product/add/:customer_id",
        name: "product add",
        component: ProductAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "users",
        },
    },
];
