import Vue from "vue";
import App from "./App.vue";
import { Tools } from "./common/tools";
import { Currency } from "@MyTelnet/mytelnet-tools-library";
import { Logger } from "@MyTelnet/mytelnet-client-logging-library";
import router from "@/routes/routes";
import store from "@/store/store";
import pulseLoader from "@/components/custom/custom-pulse-loader.component.vue";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import "element-ui/lib/theme-chalk/display.css";
import "@/styles/element-ui/element-ui.css";
import "@/styles/style.scss";
import {
    Alert,
    Aside,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    Col,
    Container,
    DatePicker,
    Dialog,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Footer,
    Form,
    FormItem,
    Header,
    Input,
    InputNumber,
    Loading,
    Main,
    Menu,
    MenuItem,
    MenuItemGroup,
    Message,
    MessageBox,
    Notification,
    Option,
    OptionGroup,
    Pagination,
    Radio,
    RadioButton,
    RadioGroup,
    Row,
    Select,
    Slider,
    Step,
    Divider,
    Progress,
    TabPane,
    Tabs,
    Submenu,
    TimeSelect,
    TimePicker,
    Tooltip,
    Upload,
    Timeline,
    TimelineItem,
    Table,
    Tree,
    Tag,
    Switch,
    TableColumn,
    Steps,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem,
    PageHeader,
    Popover,
    Popconfirm,
    Drawer,
    Descriptions,
    DescriptionsItem,
    Transfer,
} from "element-ui";
import { IconDefinition, library } from "../local_modules/@fortawesome/fontawesome-svg-core";
locale.use(lang);
import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faCheckCircle,
    faCircle,
    faCog,
    faCogs,
    faExchangeAlt,
    faInfoCircle,
    faPhone,
    faPause,
    faSignOutAlt,
    faSpinner,
    faTachometerAlt,
    faTimesCircle,
    faUser,
    faUserCircle,
    faUsers,
    faUserTie,
    faPhoneVolume,
    faListOl,
    faChartBar,
    faQuestion,
    faExclamationCircle,
    faEye,
    faEyeSlash,
    faEthernet,
    faHeadset,
    faArrowAltCircleLeft,
    faMoneyBill,
    faFileAlt,
    faFileInvoice,
    faHistory,
    faWifi,
    faBox,
    faCube,
    faMobile,
    faBoxOpen,
    faIdBadge,
    faTimes,
    faPhoneSquare,
    faServer,
    faCalendar,
    faExternalLinkSquareAlt,
    faSortNumericDown,
    faQuestionCircle,
    faMapMarked,
    faTools,
} from "../local_modules/@fortawesome/free-solid-svg-icons";
import {
    faPause as faPause_light,
    faPlay as faPlay_light,
    faPen as faPen_light,
    faInfoCircle as faInfoCircle_light,
    faWallet as faWallet_light,
    faUsers as faUsers_light,
    faPhoneVolume as faPhoneVolume_light,
    faLaughWink as faLaughWink,
    faUserHeadset as faUserHeadset_light,
    faPhoneSquareAlt as faPhoneSquareAlt_light,
    faCreditCard as faCreditCard_light,
    faTimes as faTimes_light,
    faListOl as faListOl_light,
    faPhoneSlash as faPhoneSlash_light,
    faCassetteTape as faCassetteTape_light,
} from "../local_modules/@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "../local_modules/@fortawesome/vue-fontawesome";
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(Button.name, Button);
Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(Dialog.name, Dialog);
Vue.component(InputNumber.name, InputNumber);
Vue.component(TabPane.name, TabPane);
Vue.component(Tabs.name, Tabs);
Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);
Vue.component(Submenu.name, Submenu);
Vue.component(Form.name, Form);
Vue.component(Input.name, Input);
Vue.component(FormItem.name, FormItem);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(Radio.name, Radio);
Vue.component(RadioButton.name, RadioButton);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(TimeSelect.name, TimeSelect);
Vue.component(TimePicker.name, TimePicker);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Upload.name, Upload);
Vue.component(Alert.name, Alert);
Vue.component(Col.name, Col);
Vue.component(Row.name, Row);
Vue.component(MessageBox.name, MessageBox);
Vue.component(Notification.name, Notification);
Vue.component(Main.name, Main);
Vue.component(Header.name, Header);
Vue.component(Container.name, Container);
Vue.component(Footer.name, Footer);
Vue.component(Aside.name, Aside);
Vue.component(Badge.name, Badge);
Vue.component(MenuItemGroup.name, MenuItemGroup);
Vue.component(BreadcrumbItem.name, BreadcrumbItem);
Vue.component(Slider.name, Slider);
Vue.component(Card.name, Card);
Vue.component(Timeline.name, Timeline);
Vue.component(TimelineItem.name, TimelineItem);
Vue.component(Table.name, Table);
Vue.component(Tag.name, Tag);
Vue.component(Tree.name, Tree);
Vue.component(Switch.name, Switch);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Pagination.name, Pagination);
Vue.component(Steps.name, Steps);
Vue.component(Step.name, Step);
Vue.component(Divider.name, Divider);
Vue.component(Progress.name, Progress);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Popover.name, Popover);
Vue.component(Popconfirm.name, Popconfirm);
Vue.component(Drawer.name, Drawer);
Vue.component(Descriptions.name, Descriptions);
Vue.component(DescriptionsItem.name, DescriptionsItem);
Vue.component(Transfer.name, Transfer);
Vue.component(Breadcrumb.name, Breadcrumb);
Vue.component(PageHeader.name, PageHeader);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.component("pulse-loader", pulseLoader);
Vue.use(Loading.directive);
locale.use(lang);
library.add(
    faCircle as IconDefinition,
    faCube as IconDefinition,
    faIdBadge as IconDefinition,
    faBoxOpen as IconDefinition,
    faTimes as IconDefinition,
    faIdBadge as IconDefinition,
    faBox as IconDefinition,
    faHistory as IconDefinition,
    faFileInvoice as IconDefinition,
    faFileAlt as IconDefinition,
    faMoneyBill as IconDefinition,
    faArrowAltCircleLeft as IconDefinition,
    faTachometerAlt as IconDefinition,
    faUsers as IconDefinition,
    faUserTie as IconDefinition,
    faUser as IconDefinition,
    faCog as IconDefinition,
    faCogs as IconDefinition,
    faEyeSlash as IconDefinition,
    faEthernet as IconDefinition,
    faEye as IconDefinition,
    faChartBar as IconDefinition,
    faSignOutAlt as IconDefinition,
    faPhoneVolume as IconDefinition,
    faExchangeAlt as IconDefinition,
    faSpinner as IconDefinition,
    faCheckCircle as IconDefinition,
    faPhone as IconDefinition,
    faPause as IconDefinition,
    faCircle as IconDefinition,
    faTimesCircle as IconDefinition,
    faArrowAltCircleDown as IconDefinition,
    faInfoCircle as IconDefinition,
    faArrowAltCircleUp as IconDefinition,
    faUserCircle as IconDefinition,
    faListOl as IconDefinition,
    faExclamationCircle as IconDefinition,
    faExternalLinkSquareAlt as IconDefinition,
    faQuestion as IconDefinition,
    faArrowAltCircleUp as IconDefinition,
    faWifi as IconDefinition,
    faHeadset as IconDefinition,
    faUserCircle as IconDefinition,
    faInfoCircle as IconDefinition,
    faSignOutAlt as IconDefinition,
    faCogs as IconDefinition,
    faCog as IconDefinition,
    faUser as IconDefinition,
    faUserTie as IconDefinition,
    faUsers as IconDefinition,
    faTachometerAlt as IconDefinition,
    faArrowAltCircleDown as IconDefinition,
    faFileInvoice as IconDefinition,
    faExchangeAlt as IconDefinition,
    faSpinner as IconDefinition,
    faPhone as IconDefinition,
    faCheckCircle as IconDefinition,
    faTimesCircle as IconDefinition,
    faBox as IconDefinition,
    faArrowAltCircleLeft as IconDefinition,
    faEye as IconDefinition,
    faEyeSlash as IconDefinition,
    faExclamationCircle as IconDefinition,
    faExchangeAlt as IconDefinition,
    faPhoneVolume as IconDefinition,
    faListOl as IconDefinition,
    faPen_light as IconDefinition,
    faInfoCircle_light as IconDefinition,
    faUsers_light as IconDefinition,
    faWallet_light as IconDefinition,
    faUserHeadset_light as IconDefinition,
    faPhoneSquareAlt_light as IconDefinition,
    faLaughWink as IconDefinition,
    faPhoneVolume_light as IconDefinition,
    faUsers_light as IconDefinition,
    faPause_light as IconDefinition,
    faPlay_light as IconDefinition,
    faCreditCard_light as IconDefinition,
    faTimes_light as IconDefinition,
    faListOl_light as IconDefinition,
    faPhoneSlash_light as IconDefinition,
    faPhoneSquare as IconDefinition,
    faServer as IconDefinition,
    faHistory as IconDefinition,
    faCassetteTape_light as IconDefinition,
    faCalendar as IconDefinition,
    faSortNumericDown as IconDefinition,
    faQuestionCircle as IconDefinition,
    faMapMarked as IconDefinition,
    faTools as IconDefinition,
    faMobile as IconDefinition,
);
Vue.config.productionTip = false;
Vue.prototype.$appName = "Mytelnet Partner Portal";
Vue.prototype.$message = Message;
Vue.prototype.$messaged = Message;
Vue.prototype.$notify = Notification;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$logger = Logger;
Vue.prototype.$tools = Tools;
Vue.prototype.$currency = Currency;
import cogLoaderComponent from "@/components/custom/cog-loader.component.vue";
Vue.component("cog-loader", cogLoaderComponent);
console.log(
    "%cMyTelnet\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t Voice | Data | Cloud",
    "color: #fff;background:#202020;font-size:16px;padding:16px;width:1600px;font-family: Poppins, sans-serif !important;",
);
console.log(`%cCopyright Mytelnet (PTY) Ltd 2013 - ${new Date().getFullYear()} - All rights reserved`, "color: #00c694;font-family: Poppins, sans-serif !important;");
new Vue({
    router,
    store,
    el: "#app",
    render: (h) => h(App),
}).$mount("#app");
