const FibreCoverageChecker = () => import("@/views/utilities/utilities-fibre-coverage.view.vue");

export const utilityRoutes = [
    {
        path: "/utilities/fibre/checker",
        name: "fibre coverage",
        component: FibreCoverageChecker,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: "map-marked",
        },
    },
];
