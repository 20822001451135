
import Vue from "vue";
import { Cookie } from "./common/cookie";
import { Theme } from "./common/theme";
const sideBarComponent = () => import("@/components/custom/custom-side-bar.component.vue");
const devBannerComponent = () => import("@/components/custom/custom-dev-banner.component.vue");
export default Vue.extend({
    components: {
        "side-bar": sideBarComponent,
        "dev-banner": devBannerComponent,
    },
    data() {
        return {
            isCollapse: false,
        };
    },
    computed: {
        showMenu() {
            if ((this.$route as any).meta.isPublic === true || (this.$route as any).meta.sideNavBar === false) {
                return false;
            } else {
                return true;
            }
        },
        environment() {
            return process.env.VUE_APP_ENV ? process.env.VUE_APP_ENV : "Production";
        },
        maintenance() {
            return process.env.VUE_APP_MAINTENANCE_MODE === "true" ? true : false;
        },
    },
    mounted() {
        const value = Cookie.get("THEME");
        if (value) {
            Theme.setTheme("dark-mode");
        }
        if ((this.$route as any).meta.isPublic) {
            Theme.setTheme("auth");
        }
        if (this.maintenance) {
            this.$router.push("/maintenance");
        }
        this.$root.$on("side-nav-toggle", (value) => {
            this.isCollapse = value;
        });
    },
    methods: {
        toggleMargin() {
            if (this.showMenu) {
                return this.isCollapse ? "margin-left:64px" : "margin-left:240px";
            }
            return "";
        },
        getBannerClass() {
            if (this.environment != "Production") {
                return this.showMenu ? `margin-left-sidebar-space padding-top padding-left padding-right` : `padding-top padding-left padding-right`;
            } else {
                return "";
            }
        },
    },
});
