import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";
import { Cookie } from "@/common/cookie";
import { accountRoutes } from "./account.routes";
import { errorRoutes } from "./error.routes";
import { Theme } from "@/common/theme";
import { customerRoutes } from "./customer.routes";
import { addressRoutes } from "./address.routes";
import { contactRoutes } from "./contact.routes";
import { voiceRoutes } from "./voice.routes";
import { cerebroRoutes } from "@/routes/cerebro.routes";
import { dataRoutes } from "./data.routes";
import { utilityRoutes } from "./utilities.routes";
import { productRoutes } from "./product.route";
import { orderRoutes } from "./order.routes";
const Base = () => import("@/views/base.view.vue");
const Login = () => import("@/views/auth/auth-login.view.vue");
const PasswordReset = () => import("@/views/auth/auth-password-reset.view.vue");
const ForgotPassword = () => import("@/views/auth/auth-forgot-password.view.vue");
const Dashboard = () => import("@/views/dashboard/dashboard.view.vue");
const GeneralMaintainence = () => import("@/views/general/general-maintenance.view.vue");

Vue.use(VueRouter);
const routes = [
    // [/]
    {
        path: "/",
        name: "main",
        redirect: "/dashboard",
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: null,
        },
    },
    // [auth]
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null,
        },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null,
        },
    },
    {
        path: "/password-reset",
        name: "password-reset",
        component: PasswordReset,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: null,
        },
    },
    // [dashboard]
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: "tachometer-alt",
        },
    },
    // [account]
    {
        path: "/account",
        name: "account",
        redirect: "/account/manage",
        component: Base,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: "users",
        },
        children: accountRoutes,
    },
    // [errors]
    {
        path: "*",
        redirect: "/not-found",
        name: "catch all",
        component: Base,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: "circle",
        },
        children: errorRoutes,
    },
    // [customers]
    {
        path: "/customers",
        name: "My Customers",
        component: Base,
        meta: {
            navigation: true,
            sideNavBar: false,
            isPublic: false,
            icon: "users",
        },
        children: customerRoutes,
    },

    // [voice]
    {
        path: "/voice",
        component: Base,
        name: "Voice",
        meta: {
            navigation: true,
            sideNavBar: true,
            icon: "phone",
        },
        children: voiceRoutes,
    },
    //
    {
        path: "/utilities",
        component: Base,
        name: "Utilities",
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: false,
            icon: "tools",
        },
        children: utilityRoutes,
    },
    // [address]
    {
        path: "/address",
        component: Base,
        name: "addresss",
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "users",
        },
        children: addressRoutes,
    },
    // [contact]
    {
        path: "/contact",
        component: Base,
        name: "contact",
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "users",
        },
        children: contactRoutes,
    },
    {
        path: "/maintenance",
        name: "maintenance",
        component: GeneralMaintainence,
        meta: {
            navigation: false,
            sideNavBar: false,
            isPublic: true,
            icon: "exchange-alt",
        },
    },
    {
        path: "/product",
        name: "product",
        component: Base,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "exchange-alt",
        },
        children: productRoutes,
    },
    // [voice cerebro ]
    {
        name: "voice",
        path: "/voice",
        component: Base,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "phone",
        },
        children: cerebroRoutes,
    },
    // [voice cerebro ]
   /*  {
        name: "Data",
        path: "/data",
        component: Base,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: "phone",
        },
        children: dataRoutes,
    }, */
    // [voice cerebro ]
    {
        name: "Orders",
        path: "/order",
        component: Base,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "phone",
        },
        children: orderRoutes,
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
// [route gaurd and persmissions blocker]
router.beforeEach((to: any, from, next) => {
    // [check for cookie]
    const token = Cookie.get("ACCESS_TOKEN");
    console.log("token", token);
    let isAuthenticated = false;
    let isAuthorized = false;
    if (token) {
        const validToken = Cookie.parseToken(token);
        if (validToken) {
            if (validToken.userId && validToken.siteKey === "portal.mytelnet.co.za") {
                isAuthenticated = true;
                isAuthorized = true;
            }
        }
    }
    // [routes]
    if (isAuthenticated) {
        if (isAuthorized) {
            next();
        } else {
            // [unauthorized screen]
            if (to.path !== "/unauthorized") {
                next("/unauthorized");
            }
        }
    } else {
        // [back to login screen]
        if (!to.meta.isPublic) {
            Cookie.remove("ACCESS_TOKEN");
            Theme.setTheme("auth");
            next(`/login?ref=${to.path}`);
        }
        next();
    }
});
// [swallow duplicate routing error when a link is clicked]
const originalPush: any = Router.prototype.push;
Router.prototype.push = function push(location: any) {
    return originalPush.call(this, location).catch((err: any) => err);
};
export default router;
