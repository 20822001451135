const ContactAdd = () => import("@/views/customer/contact/customer-contact-add.view.vue");
const ContactManage = () => import("@/views/customer/contact/customer-contact-manage.view.vue");
export const contactRoutes = [
    {
        path: "/contact/add/:customer_id",
        name: "contact add",
        component: ContactAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "server",
        },
    },
    {
        path: "/contact/manage/:customer_id/:contact_id",
        name: "contact manage",
        component: ContactManage,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "server",
        },
    },
];
