const OrderAdd = () => import("@/views/order/order-add.view.vue");
// const OrderUpdate = () => import("@/views/order/order-update.view.vue");
// const OrderItemUpdate = () => import("@/views/order/order-item-update.view.vue");
// const OrderPartRequest = () => import("@/views/order/order-part-request.view.vue");
// const OrderDocuments = () => import("@/views/order/documents/order-document-list.view.vue");
// const OrderAddDocument = () => import("@/views/order/documents/order-document-add.view.vue");
export const orderRoutes = [
    {
        path: "/order/add/:id",
        name: "order add",
        component: OrderAdd,
        meta: {
            sidebar: true,
            icon: "tachometer-alt",
            groups: ["AgilityWeb-Fulfillment"],
        },
    },
    // {
    //     path: '/order/update/:id/:dealId',
    //     name: "order update",
    //     component: OrderUpdate,
    //     meta: {
    //         sidebar: true,
    //         icon: 'tachometer-alt',
    //         groups: ["AgilityWeb-Fulfillment"]
    //     }
    // },
    // {
    //     path: '/order/item/update/:id/:dealId/:itemType/:isDeal',
    //     name: "order product update",
    //     component: OrderItemUpdate,
    //     meta: {
    //         sidebar: true,
    //         icon: 'tachometer-alt',
    //         groups: ["AgilityWeb-Fulfillment"]
    //     }
    // },
    // {
    //     path: '/order/part-requests/:customerId/:orderId',
    //     name: "part-requests",
    //     component: OrderPartRequest,
    //     meta: {
    //         sidebar: true,
    //         icon: 'tachometer-alt',
    //         groups: ["AgilityWeb-Fulfillment"]
    //     }
    // },

    // {
    //     path: '/order/documents/:id/:dealId',
    //     name: "order documents",
    //     component: OrderDocuments,
    //     meta: {
    //         sidebar: true,
    //         icon: 'tachometer-alt',
    //         groups: ["AgilityWeb-Fulfillment"]
    //     }
    // },

    // {
    //     path: '/order/documents/add/:id/:dealId',
    //     name: "add order documents",
    //     component: OrderAddDocument,
    //     meta: {
    //         sidebar: true,
    //         icon: 'tachometer-alt',
    //         groups: ["AgilityWeb-Fulfillment"]
    //     }
    // },
];
