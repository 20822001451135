const Unauthorized = () => import("@/views/general/general-unauthorized.view.vue");
const NotFound = () => import("@/views/general/general-not-found.view.vue");
export const errorRoutes = [
    {
        path: "/not-found",
        name: "not found",
        component: NotFound,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "circle",
        },
    },
    {
        name: "unauthorized",
        path: "/unauthorized",
        component: Unauthorized,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "circle",
        },
    },
];
