import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: { loading: false },
    mutations: {
        loading(state, newLoadingState) {
            state.loading = newLoadingState;
        },
    },
    getters: {
        loading(state) {
            return state.loading;
        },
    },
});
