const AddSipPeer = () => import("@/views/voice/cerebro/sip-peer/cerebro-sip-peer-add.view.vue");
const UpdateSipPeer = () => import("@/views/voice/cerebro/sip-peer/cerebro-sip-peer-update.view.vue");
const ManageSipNumbers = () => import("@/views/voice/cerebro/sip-peer/cerebro-sip-peer-numbers-manage.view.vue");
const CallForwarding = () => import("@/views/voice/cerebro/call-forwarding/cerebro-call-forwarding.view.vue");
export const cerebroRoutes = [
    {
        name: "update routing peer",
        path: "/voice/sip-peer/:customer_id/:username",
        component: UpdateSipPeer,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "phone-office",
        },
    },
    {
        name: "add routing peer",
        path: "/voice/peer/add/:customer_id",
        component: AddSipPeer,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "phone-office",
        },
    },
    {
        name: "manage routing peer numbers",
        path: "/voice/manage-peer-numbers/:customer_id/:username",
        component: ManageSipNumbers,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "phone-office",
        },
    },
    {
        name: "manage sip peer call forwarding",
        path: "/voice/call-forwarding/:customer_id/:username",
        component: CallForwarding,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "phone-office",
        },
    },
];
