import moment from "moment";
export class Tools {
    public static formatDate(dateObj: string | Date, formatString: string = "DD/MM/YYYY"): string {
        switch (formatString) {
            case "api":
                formatString = "YYYY-MM-DD hh:mm:ss";
                break;
        }
        return moment(dateObj).format(formatString);
    }

    public static isUndefined(value: any): boolean {
        return typeof value === "undefined";
    }

    public static isNull(value: any): boolean {
        return value === null;
    }

    public static isNullOrUndefined(value: any): boolean {
        return Tools.isUndefined(value) || Tools.isNull(value);
    }

    public static isNullUndefinedOrBlank(value: any): boolean {
        return Tools.isUndefined(value) || Tools.isNull(value) || value === "";
    }

    public static isBoolean(value: any): boolean {
        if (Tools.isNullOrUndefined(value)) return false;
        return typeof value === "boolean";
    }

    public static isNumber(value: any): boolean {
        if (Tools.isNullOrUndefined(value)) return false;
        return typeof value === "number" || !isNaN(value);
    }

    public static toFixed(num: number, precision: number): number {
        const firstFix = Math.round(+(num + "e" + precision));
        const secondFix = +(firstFix + "e" + -precision);
        return Number.parseFloat(secondFix.toFixed(precision));
    }

    public static formatPhoneNumber(value: string) {
        if (Tools.isNullOrUndefined(value)) {
            return value;
        }
        if (`${value}`.indexOf("0") === 0) {
            return `+27${value.substr(1)}`;
        }
    }

    public static uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    public static formatNumber(amount: number | string, decimalPlaces?: number, decimalFormatter?: string, thousandsFormatter?: string, symbol?: string | boolean): number {
        decimalPlaces = Tools.isNullOrUndefined(decimalPlaces) ? 2 : Math.abs(decimalPlaces as number);
        decimalFormatter = Tools.isNullOrUndefined(decimalFormatter) ? "." : decimalFormatter;
        thousandsFormatter = Tools.isNullOrUndefined(thousandsFormatter) ? " " : thousandsFormatter;
        if (!Tools.isNumber(amount)) amount = Number.parseFloat(amount as string);

        amount = Math.abs((amount as number) || 0);
        amount = Tools.toFixed(amount, decimalPlaces);
        return amount;
    }

    public static absoluteNumber(value) {
        return Math.abs(value);
    }

    public static generateRandom16CharString(): string {
        let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let result = "";
        for (let i = 0, n = charset.length; i < 16; ++i) {
            result += charset.charAt(Math.floor(Math.random() * n));
        }
        return result;
    }

    public static formatCurrency(amount: number, decimalPlaces?: number, decimalFormatter?: string, thousandsFormatter?: string): string {
        decimalPlaces = Tools.isNullOrUndefined(decimalPlaces) ? 2 : Math.abs(decimalPlaces as number);
        decimalFormatter = Tools.isNullOrUndefined(decimalFormatter) ? "." : decimalFormatter;
        thousandsFormatter = Tools.isNullOrUndefined(thousandsFormatter) ? " " : thousandsFormatter;
        amount = parseFloat((Math.round(amount * 100) / 100).toFixed(2));
        let numberValueSplit = `${amount}.0`.split(".");
        let decimalValue = "";
        if (decimalPlaces > 0) {
            decimalValue = decimalFormatter + numberValueSplit[1] + "0".repeat(decimalPlaces - numberValueSplit[1].length);
        }
        let result =
            amount < 0
                ? `R ( ${numberValueSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsFormatter || "")} ${decimalValue})`.replace("-", "")
                : `R ${numberValueSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsFormatter || "")} ${decimalValue}`;
        return result;
    }

    public static formatCurrencyNonAccounting(amount: number, decimalPlaces?: number, decimalFormatter?: string, thousandsFormatter?: string): string {
        decimalPlaces = Tools.isNullOrUndefined(decimalPlaces) ? 2 : Math.abs(decimalPlaces as number);
        decimalFormatter = Tools.isNullOrUndefined(decimalFormatter) ? "." : decimalFormatter;
        thousandsFormatter = Tools.isNullOrUndefined(thousandsFormatter) ? " " : thousandsFormatter;
        amount = parseFloat((Math.round(amount * 100) / 100).toFixed(2));
        let numberValueSplit = `${amount}.0`.split(".");
        let decimalValue = "";
        if (decimalPlaces > 0) {
            decimalValue = decimalFormatter + numberValueSplit[1] + "0".repeat(decimalPlaces - numberValueSplit[1].length);
        }
        let result =
            amount < 0
                ? `R ${numberValueSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsFormatter || "")} ${decimalValue}`.replace("-", "")
                : `R ${numberValueSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsFormatter || "")} ${decimalValue}`;
        return result;
    }

    public static formatCreditCardNumber(value: string) {
        const v = value.replace(/\D/gi, "").substring(0, 16);
        const matches = v.match(/\d{4,16}/g);
        const match: any = (matches && matches[0]) || "";
        const parts: any[] = [];

        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }

        if (parts.length) {
            return parts.join(" ");
        }
        return v;
    }

    public static formatCreditCardExpiration(value: string) {
        const v = value.replace(/\D/gi, "").substring(0, 4);
        const matches = v.match(/([0-1][0-9])([1-9][0-9])/);
        if (matches) {
            let month = 0;
            let year = 0;
            try {
                month = Number.parseInt(matches[1], 10);
                year = Number.parseInt(matches[2], 10);
            } catch (xe) {
                return value;
            }
            return `${month < 10 ? "0" : ""}${month}/${year}`;
        }
        return v;
    }

    public static toTitleCase(str: string) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    public static clone(data: any) {
        return JSON.parse(JSON.stringify(data));
    }

    public static isMobile() {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                navigator.userAgent.substr(0, 4),
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    public static getCardType(ccNumber: any) {
        // ref: https://stackoverflow.com/a/5911300
        // visa
        let re = new RegExp("^4");
        if (ccNumber.match(re) != null) {
            return "Visa";
        }
        // Mastercard
        if (/^5[1-5]/.test(ccNumber)) {
            return "Mastercard";
        }
        // AMEX
        re = new RegExp("^3[47]");
        if (ccNumber.match(re) != null) {
            return "AMEX";
        }
        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (ccNumber.match(re) != null) {
            return "Discover";
        }
        // Diners
        re = new RegExp("^36");
        if (ccNumber.match(re) != null) {
            return "Diners";
        }
        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (ccNumber.match(re) != null) {
            return "Diners";
        }
        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (ccNumber.match(re) != null) {
            return "JCB";
        }
        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (ccNumber.match(re) != null) {
            return "Visa";
        }
        return "";
    }

    public static generateShortUID() {
        let firstPart = (Math.random() * 46656) | 0;
        let secondPart = (Math.random() * 46656) | 0;
        let partOne = ("000" + firstPart.toString(36)).slice(-3);
        let partTwo = ("000" + secondPart.toString(36)).slice(-3);
        return partOne + partTwo;
    }

    public static groupByList(xs: any[], key: any): any {
        return xs.reduce((rv, x) => {
            let v = key instanceof Function ? key(x) : x[key];
            (rv[v] = rv[v] || []).push(x);
            return rv;
        }, {});
    }

    public static formatGroupedListToStandard(dataSet: any[]) {
        const result = Object.keys(dataSet).map((key: any) => {
            let productCount = 0;
            for (let item in dataSet[key]) {
                productCount++;
            }
            return {
                Id: this.uuidv4(),
                DealId: key,
                TemplateDescription: `${productCount} Total Products`,
                Quantity: "",
                UnitPrice: "",
                Children: dataSet[key],
                BPOItemType: dataSet[key][0].BPOItemType,
                IsDeal: dataSet[key][0].IsDeal,
                BPOHeaderId: dataSet[key][0].BPOHeaderId,
                CanDelete: dataSet[key][0].CanDelete,
                CanEdit: dataSet[key][0].CanEdit,
                Processed: dataSet[key][0].Processed,
                IsDealProcessed: dataSet[key][0].IsDealProcessed,
                UnitPriceInclVat: "",
                CancellationStatus: dataSet[key][0].CancellationStatus,
            };
        });
        return result;
    }

    public static prefixTMSID(tmsId: string): any {
        if (tmsId.length >= 5) {
            return tmsId;
        }
        return this.prefixTMSID(`0${tmsId}`);
    }
}
