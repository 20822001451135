const DomainAddView = () => import("@/views/voice/fusion/domain/fusion-domain-add.view.vue");
const DomainManagementView = () => import("@/views/voice/fusion/domain/fusion-domain-manage.view.vue");
const DomainListView = () => import("@/views/voice/fusion/domain/fusion-domain-list.view.vue");
const DomainUserManagementView = () => import("@/views/voice/fusion/user/fusion-user-manage.view.vue");
const DomainUserAddView = () => import("@/views/voice/fusion/user/fusion-user-add.view.vue");
const DomainIVRManagementView = () => import("@/views/voice/fusion/ivr/fusion-ivr-manage.view.vue");
const DomainIVRAddView = () => import("@/views/voice/fusion/ivr/fusion-ivr-add.view.vue");
const DomainRingGroupManagementView = () => import("@/views/voice/fusion/ring-group/fusion-ring-group-manage.view.vue");
const DomainRingGroupAddView = () => import("@/views/voice/fusion/ring-group/fusion-ring-group-add.view.vue");
const DomainNumberManagementView = () => import("@/views/voice/fusion/number/fusion-number-manage.view.vue");
const DomainNumberAddView = () => import("@/views/voice/fusion/number/fusion-number-add.view.vue");
const RoutingCompanyView = () => import("@/views/voice/cerebro/routing-company/cerebro-routing-company-list.view.vue");
const TimeConditionAddView = () => import("@/views/voice/fusion/time-conditions/fusion-time-conditions-add.view.vue");
const TimeCondtionUpdateView = () => import("@/views/voice/fusion/time-conditions/fusion-time-condtions-manage.view.vue");
const RecordingAddView = () => import("@/views/voice/fusion/recording/fusion-recording-add.view.vue");
const RecordingUpdateView = () => import("@/views/voice/fusion/recording/fusion-recording-manage.view.vue");
const BulkTopUpView = () => import("@/views/voice/cerebro/top-up/cerebro-bulk-top-up.view.vue");
const NumberPoolManagementView = () => import("@/views/voice/cerebro/number-pool-management/cerebro-number-pool-management.view.vue");
const NumberReserveView = () => import("@/views/voice/cerebro/number-pool-management/cerebro-number-reserve.view.vue");
const RateSheetBrowserView = () => import("@/views/voice/cerebro/rate-sheet-browser/cerebro-rate-sheet-browser.view.vue");
export const voiceRoutes = [
    {
        path: "/voice/domain/add/:customer_id?",
        name: "Hosted Domain Add",
        component: DomainAddView,
        meta: {
            sidebar: false,
            icon: "phone-office",
        },
    },
    {
        path: "/voice/domain/:domain_uuid/:tab?",
        name: "Hosted Domain Management",
        component: DomainManagementView,
        meta: {
            sidebar: false,
            icon: "phone-office",
        },
    },
    {
        path: "/voice/domain/list",
        name: "Hosted Domains",
        component: DomainListView,
        meta: {
            sidebar: true,
            icon: "phone-office",
        },
    },
    {
        path: "/voice/routing-company/list",
        name: "Routing Companies",
        component: RoutingCompanyView,
        meta: {
            sidebar: false,
            icon: "chart-network",
        },
    },
    {
        path: "/voice/domain/user/add/:domain_uuid",
        name: "Domain User Add",
        component: DomainUserAddView,
        meta: {
            sidebar: false,
            icon: "phone-office",
        },
    },
    {
        path: "/voice/domain/user/manage/:domain_uuid/:user_uuid",
        name: "Domain User Management",
        component: DomainUserManagementView,
        meta: {
            sidebar: false,
            icon: "phone-office",
        },
    },
    {
        path: "/voice/domain/ivr/add/:domain_uuid",
        name: "Domain IVR Add",
        component: DomainIVRAddView,
        meta: {
            sidebar: false,
            icon: "phone-office",
        },
    },
    {
        path: "/voice/domain/ivr/manage/:domain_uuid/:ivr_uuid",
        name: "Domain IVR Management",
        component: DomainIVRManagementView,
        meta: {
            sidebar: false,
            icon: "phone-office",
        },
    },
    {
        path: "/voice/domain/ring-group/add/:domain_uuid",
        name: "Domain Ring Group Add",
        component: DomainRingGroupAddView,
        meta: {
            sidebar: false,
            icon: "phone-office",
        },
    },
    {
        path: "/voice/domain/ring-group/manage/:domain_uuid/:ring_group_uuid",
        name: "Domain Ring Group Management",
        component: DomainRingGroupManagementView,
        meta: {
            sidebar: false,
            icon: "server",
        },
    },
    {
        path: "/voice/domain/number/add/:domain_uuid",
        name: "Domain Number Add",
        component: DomainNumberAddView,
        meta: {
            sidebar: false,
            icon: "server",
        },
    },
    {
        path: "/voice/domain/number/manage/:domain_uuid/:number",
        name: "Domain Number Management",
        component: DomainNumberManagementView,
        meta: {
            sidebar: false,
            icon: "server",
        },
    },
    {
        path: "/voice/domain/number/manage/:domain_uuid",
        name: "Domain Number Manage",
        component: DomainNumberAddView,
        meta: {
            sidebar: false,
            icon: "server",
        },
    },
    {
        path: "/voice/domain/time-condition/add/:domain_uuid",
        name: "Time Condition Add View",
        component: TimeConditionAddView,
        meta: {
            sidebar: false,
            icon: "server",
        },
    },
    {
        path: "/voice/domain/time-condition/manage/:domain_uuid/:time_condition_uuid",
        name: "Time Condition Update",
        component: TimeCondtionUpdateView,
        meta: {
            sidebar: false,
            icon: "server",
        },
    },
    {
        path: "/voice/domain/recording/add/:domain_uuid",
        name: "Recording Add",
        component: RecordingAddView,
        meta: {
            sidebar: false,
            icon: "server",
        },
    },
    {
        path: "/voice/domain/recording/manage/:domain_uuid/:recording_uuid",
        name: "Recording Update",
        component: RecordingUpdateView,
        meta: {
            icon: "server",
            navigation: false,
            sideNavBar: true,
            isPublic: false,
        },
    },
    {
        path: "/voice/top-up/bulk",
        name: "Bulk Top Up",
        component: BulkTopUpView,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "server",
        },
    },

    {
        path: "/voice/number-pool-management",
        name: "Number Management",
        component: NumberPoolManagementView,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: "sort-numeric-down",
        },
    },

    {
        path: "/voice/number/reserve/:routing_number_id",
        name: "Number Reserve",
        component: NumberReserveView,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "sort-numeric-down",
        },
    },
    {
        path: "/voice/rate-sheet/",
        name: "Rate Sheets",
        component: RateSheetBrowserView,
        meta: {
            navigation: true,
            sideNavBar: true,
            isPublic: false,
            icon: "file-alt",
        },
    },
];
