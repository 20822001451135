const AddressAdd = () => import("@/views/customer/address/customer-address-add.view.vue");
const AddressManage = () => import("@/views/customer/address/customer-address-manage.view.vue");
export const addressRoutes = [
    {
        path: "/address/add/:customer_id",
        name: "address add",
        component: AddressAdd,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "server",
        },
    },
    {
        path: "/address/manage/:customer_id/:address_id",
        name: "address manage",
        component: AddressManage,
        meta: {
            navigation: false,
            sideNavBar: true,
            isPublic: false,
            icon: "server",
        },
    },
];
